import { combineReducers } from '@reduxjs/toolkit';
import homeReducer from './homeSlice';
import authReducer from './authSlice';
import surveyReducer from './surveySlice';
import announcementReducer from './announcementSlice';
import feedReducer from './feedSlice';
import userReducer from './userSlice';
import groupReducer from './groupSlice';
import codeReducer from './codeSlice';

export const rootReducer = combineReducers({
  home: homeReducer,
  auth: authReducer,
  survey: surveyReducer,
  announcement: announcementReducer,
  feed: feedReducer,
  user: userReducer,
  group: groupReducer,
  code: codeReducer
});
