import axios from 'axios';

export default axios.create({
  //Production
  baseURL: 'https://rmate-backend.herokuapp.com/api',
  //Development
  // baseURL: 'https://rmate-dev.herokuapp.com/api',
  //Local
  //baseURL: "http://37.223.132.99:5000/api",
  withCredentials: true
});

// import axios from 'axios';
// import tough from 'tough-cookie';
// import axiosCookieJarSupport from 'axios-cookiejar-support';

// const instance = axios.create({
//   baseURL: 'https://rmate-backend.herokuapp.com/api',
//   withCredentials: true,
//   // WARNING: This value will be ignored.
//   jar: new tough.CookieJar()
// });

// // Set directly after wrapping instance.
// axiosCookieJarSupport(instance);
// instance.defaults.jar = new tough.CookieJar();

// export default instance;
