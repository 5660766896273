import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import backendAPI from '../apis/backendAPI';

export const fetchHome = createAsyncThunk('home/fetchHome', async () => {
  const response = await backendAPI.request('/home', { withCredentials: true });
  return response.data;
});

const homeSlice = createSlice({
  name: 'home',
  initialState: {
    homeData: {},
    reload: false,
    loading: false
  },
  reducers: {},
  extraReducers: {
    // // Add reducers for additional action types here, and handle loading state as needed
    [fetchHome.pending]: (state, action) => {
      state.loaded = true;
    },
    [fetchHome.fulfilled]: (state, action) => {
      state.loaded = true;
      state.homeData = action.payload;
    }
  }
});

//Select slice name to get access to states
export const homeSelector = state => state.home;

export const { setHomeState } = homeSlice.actions;

//export single reducers
export default homeSlice.reducer;
