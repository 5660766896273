import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';
import backendAPI from '../apis/backendAPI';
import { serialize } from 'object-to-formdata';

export const fetchAnnouncements = createAsyncThunk('announcement/fetchAnnouncements', async () => {
  const response = await backendAPI.get('/announcements', { withCredentials: true });
  response.data = _.forEach(response.data, item => {
    item.created_on = new Date(item.created_on).toLocaleString();
    item.sent_on = new Date(item.sent_on).toLocaleString();
    if (item.sent === true) {
      item.sent = new Date(item.sent_on).toLocaleString();
    } else {
      item.sent = 'Not Sent';
    }
  });
  return response.data;
});

export const createAnnouncements = createAsyncThunk('announcement/createAnnouncements', async formValues => {
  // alert(JSON.stringify(formValues));
  // console.log(formValues);

  //If image exists
  if (formValues.image !== null) {
    formValues.image.toBlob(async blob => {
      const form = new FormData();

      form.append('name', formValues.name);
      form.append('title', formValues.title);
      form.append('body', formValues.body);
      form.append('category', formValues.category);
      form.append('file', blob, 'test.png');

      await backendAPI
        .post('/announcements/add', form, {
          withCredentials: true,
          headers: { 'Content-Type': 'multipart/form-data' }
        })
        .then(response => {})
        .catch(error => {
          alert(error);
        });
    });
  }
  //Else no image
  else {
    const form = new FormData();
    form.append('name', formValues.name);
    form.append('title', formValues.title);
    form.append('body', formValues.body);
    form.append('category', formValues.category);

    // const form = serialize({
    //   name: formValues.name,
    //   title: formValues.title,
    //   body: formValues.body,
    //   category: formValues.category
    // });

    await backendAPI
      .post('/announcements/add', form, {
        withCredentials: true,
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      .then(response => {
        console.log('uploaded with no image');
      })
      .catch(error => {
        alert(error);
      });
  }
});

export const editAnnouncements = createAsyncThunk('announcement/editAnnouncements', async data => {
  let id = JSON.stringify(data.selectedAnnouncement.id);
  let formValues = data.data;

  const editValues = _.pick(formValues, 'name', 'title', 'body');
  const remainValues = _.pick(data.selectedAnnouncement, 'recipients', 'category');
  const mergeValues = _.merge(editValues, remainValues);

  // alert(JSON.stringify(mergeValues));
  // console.log(data.image);

  //  Serializing Data
  //  const form = serialize({
  //   name: mergeValues.name,
  //   title: mergeValues.title,
  //   body: mergeValues.body,
  //   recipients: mergeValues.recipients,
  //   category: mergeValues.category
  // });

  //If image exists
  if (data.image) {
    data.image.toBlob(async blob => {
      const form = new FormData();

      form.append('name', mergeValues.name);
      form.append('title', mergeValues.title);
      form.append('body', mergeValues.body);
      form.append('category', mergeValues.category);
      form.append('file', blob, 'test.png');

      await backendAPI
        .post(`/announcements/${id}/edit`, form, {
          withCredentials: true,
          headers: { 'Content-Type': 'multipart/form-data' }
        })
        .then(response => {})
        .catch(error => {
          alert(error);
        });
    });
  }
  //Else no image
  else {
    const form = new FormData();
    form.append('name', mergeValues.name);
    form.append('title', mergeValues.title);
    form.append('body', mergeValues.body);
    form.append('category', mergeValues.category);

    await backendAPI
      .post(`/announcements/${id}/edit`, form, {
        withCredentials: true,
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      .then(response => {})
      .catch(error => {
        alert(error);
      });
  }
});

export const deleteAnnouncements = createAsyncThunk('announcement/deleteAnnouncements', async id => {
  await backendAPI
    .get(`/announcements/${id}/remove`, { withCredentials: true })
    .then(response => {})
    .catch(error => {
      alert(error);
    });
});

export const sendAnnouncements = createAsyncThunk('announcement/sendAnnouncements', async data => {
  let id = JSON.stringify(data.selectedAnnouncement.id);
  let recipients = JSON.stringify(data.data.recipients);
  let group = JSON.stringify(data.data.associated_groups);

  let formValues = data.data;

  const editValues = _.pick(formValues, 'name', 'title', 'body', 'recipients');
  const remainValues = _.pick(data.selectedAnnouncement, 'category');
  const mergeValues = _.merge(editValues, remainValues);

  // alert(JSON.stringify(mergeValues));

  // Sending to ONLY recipients
  if (group === undefined && recipients !== null) {
    let payload = {
      recipients: mergeValues.recipients,
      groups: null
    };

    await backendAPI
      .post(`/announcements/${id}/send`, payload, {
        withCredentials: true,
        timeout: 60000
      })
      .then(response => {})
      .catch(error => {
        alert(error);
      });
  }

  //Sending to ONLY Groups
  else if (group !== undefined && recipients === undefined) {
    // let groupID = JSON.stringify(data.data.associated_groups[0].value);
    // let groupName = JSON.stringify(data.data.associated_groups[0].label);

    //[{"value":390,"label":"Test"},{"value":391,"label":"test 2"}]
    //alert(JSON.stringify(data.data.associated_groups));

    let groupArray = [];

    data.data.associated_groups.forEach(function(arrayItem) {
      groupArray.push(arrayItem.value);
    });

    let payload = {
      recipients: null,
      groups: groupArray
    };

    await backendAPI
      .post(`/announcements/${id}/send`, payload, {
        withCredentials: true,
        timeout: 60000
      })
      .then(response => {})
      .catch(error => {
        alert(error);
      });
  }

  //Sending to BOTH Groups and RECIPIENTS
  else {
    let groupArray = [];

    data.data.associated_groups.forEach(function(arrayItem) {
      groupArray.push(arrayItem.value);
    });

    let payload = {
      recipients: mergeValues.recipients,
      groups: groupArray
    };

    await backendAPI
      .post(`/announcements/${id}/send`, payload, {
        withCredentials: true,
        timeout: 60000
      })
      .then(response => {})
      .catch(error => {
        alert(error);
      });
  }
});

const announcementSlice = createSlice({
  name: 'announcement',
  initialState: {
    loaded: false,
    error: '',
    announcements: [],
    modalName: '',
    createModal: false,
    editModal: false,
    deleteModal: false,
    sendModal: false,
    successModal: false,
    formValues: '',
    reload: false,
    selectedID: null,
    selectedAnnouncement: {}
  },
  reducers: {
    toggleCreateModal: (state, action) => {
      state.modalName = 'Create Announcement';
      state.createModal = action.payload;
    },
    toggleEditModal: (state, action) => {
      state.modalName = 'Edit Announcement';
      state.editModal = action.payload;
    },
    toggleDeleteModal: (state, action) => {
      state.modalName = 'Delete Announcement';
      state.deleteModal = action.payload;
    },
    toggleSendModal: (state, action) => {
      state.modalName = 'Send Announcement';
      state.sendModal = action.payload;
    },
    toggleSuccessModal: (state, action) => {
      state.modalName = 'Announcement Sent';
      state.successModal = action.payload;
    },
    submitForm: (state, action) => {
      state.formValues = action.payload;
      state.reload = !state.reload;
    },
    selectID: (state, action) => {
      state.selectedID = Number(action.payload);
      state.reload = !state.reload;
    },
    selectAnnouncement: (state, action) => {
      state.selectedAnnouncement = action.payload;
      state.reload = !state.reload;
    }
  },
  extraReducers: {
    // Add reducers for additional action types here, and handle loading state as needed
    //pending is loading
    [fetchAnnouncements.pending]: (state, action) => {
      state.loaded = true;
    },
    //Success
    [fetchAnnouncements.fulfilled]: (state, { payload }) => {
      state.loaded = false;
      state.announcements = payload;
      state.reload = false;
    },
    //Error
    [fetchAnnouncements.rejected]: (state, action) => {
      state.loaded = false;
      state.error = action.error;
    },
    [createAnnouncements.fulfilled]: (state, { payload }) => {
      state.reload = true;
    },
    [editAnnouncements.fulfilled]: (state, { payload }) => {
      state.reload = true;
    },
    [deleteAnnouncements.fulfilled]: (state, { payload }) => {
      state.reload = true;
    },
    [sendAnnouncements.fulfilled]: (state, { payload }) => {
      state.reload = true;
    }
  }
});

//States
export const announcementSelector = state => state.announcement;

//Functions
export const {
  toggleCreateModal,
  toggleEditModal,
  toggleDeleteModal,
  toggleSendModal,
  toggleSuccessModal,
  submitForm,
  selectID,
  selectAnnouncement
} = announcementSlice.actions;

//export single reducers
export default announcementSlice.reducer;
