import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import backendAPI from '../apis/backendAPI';

export const fetchFeed = createAsyncThunk('feed/fetchFeed', async () => {
  const response = await backendAPI.request('/post/get?q=bcoe', { withCredentials: true });
  // const response = await backendAPI.request('/post/get', { withCredentials: true });
  return response.data.data;
});

export const postPost = createAsyncThunk('feed/postPost', async data => {
  let message = data.message;
  let photo = data.image;

  // alert(JSON.stringify(data));

  //If image exists
  if (photo !== null) {
    photo.toBlob(async blob => {
      const form = new FormData();

      form.append('post', message);
      form.append('tag', 'bcoe');
      form.append('file', blob, 'test.png');

      await backendAPI
        .post(`/post/add`, form, {
          withCredentials: true,
          headers: { 'Content-Type': 'multipart/form-data' }
        })
        .then(response => {})
        .catch(error => {
          alert(error);
        });
    });
  }
  //Else no image
  else {
    const form = new FormData();
    form.append('post', message);
    form.append('tag', 'bcoe');

    await backendAPI
      .post(`/post/add`, form, {
        withCredentials: true,
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      .then(response => {
        console.log('uploaded with no image');
      })
      .catch(error => {
        alert(error);
      });
  }
});

export const deletePost = createAsyncThunk('feed/deletePost', async data => {
  let postId = data.id;

  //   alert(postId);

  let formObject = {
    post_id: postId
  };

  await backendAPI
    .post(`/post/${postId}/delete`, formObject, {
      withCredentials: true
    })
    .then(response => {})
    .catch(error => {
      alert(error);
    });
});

export const postComment = createAsyncThunk('feed/postComment', async data => {
  let comment = data.comment;
  let id = data.id;

  const form = new FormData();
  form.append('comment', comment);

  await backendAPI
    .post(`/comment/${id}/add`, form, {
      withCredentials: true,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    .then(response => {})
    .catch(error => {
      alert(error);
    });
});

export const addLikeToOriginalPost = createAsyncThunk('feed/addLikeToOriginalPost', async id => {
  //feed calls get alot - need to optimize later

  await backendAPI
    .post(`/post/${id}/like`, {
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
    .then(response => {})
    .catch(error => {
      //Have to combine with a fetch since axios is wierd with delete
      if (error.response.data.status === 'Error user already liked this post') {
        // alert(JSON.stringify(formObject));
        fetch(`https://rmate-backend.herokuapp.com/api` + `/post/${id}/unlike`, {
          method: 'DELETE',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          credentials: 'include'
        })
          .then(async response => response.json().then(async responseJson => {}))
          .catch(function(error) {
            alert(error.message);
          });
      } else {
        alert(error);
      }
    });
});

export const addLikeToComment = createAsyncThunk('feed/addLikeToComment', async data => {
  let commentID = data.comment;
  let postID = data.post;

  let formObject = {
    post_id: postID
  };

  //feed calls get alot - need to optimize later

  await backendAPI
    .post(`/comment/${commentID}/like`, formObject, {
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
    .then(response => {})
    .catch(error => {
      //Have to combine with a fetch since axios is wierd with delete
      if (error.response.data.status === 'Error user already liked this post') {
        // alert(JSON.stringify(formObject));
        fetch(`https://rmate-backend.herokuapp.com/api` + `/comment/${commentID}/unlike`, {
          method: 'DELETE',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            post_id: postID
          }),
          credentials: 'include'
        })
          .then(async response => response.json().then(async responseJson => {}))
          .catch(function(error) {
            alert(error.message);
          });
      } else {
        alert(error);
      }
    });
});

const feedSlice = createSlice({
  name: 'feed',
  initialState: {
    feedData: {},
    reload: false,
    loading: false,
    addLiked: false,
    addedPost: false
  },
  reducers: {},
  extraReducers: {
    // // Add reducers for additional action types here, and handle loading state as needed
    [fetchFeed.pending]: (state, action) => {
      state.loaded = true;
    },
    [fetchFeed.fulfilled]: (state, action) => {
      state.loaded = true;
      state.feedData = action.payload;
      state.reload = false;
      state.addedPost = false;
    },
    [postPost.pending]: (state, action) => {
      state.addedPost = false;
    },
    [postPost.fulfilled]: (state, action) => {
      state.addedPost = true;
    },
    [deletePost.pending]: (state, action) => {
      state.addLiked = false;
    },
    [deletePost.fulfilled]: (state, action) => {
      state.addLiked = true;
    },
    [addLikeToComment.pending]: (state, action) => {
      state.addLiked = false;
    },
    [addLikeToComment.fulfilled]: (state, action) => {
      state.addLiked = true;
    },
    [addLikeToOriginalPost.pending]: (state, action) => {
      state.addLiked = false;
    },
    [addLikeToOriginalPost.fulfilled]: (state, action) => {
      state.addLiked = true;
    }
  }
});

//Select slice name to get access to states
export const feedSelector = state => state.feed;

export const { setFeedState } = feedSlice.actions;

//export single reducers
export default feedSlice.reducer;
