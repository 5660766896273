import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import backendAPI from '../apis/backendAPI';

export const fetchUser = createAsyncThunk('user/fetchUser', async (payload, { dispatch, rejectWithValue }) => {
  try {
    const response = await backendAPI.get('/user/my-profile', { withCredentials: true });
    return response; // Return a value synchronously using Async-await
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response);
  }
});

export const fetchAllUsers = createAsyncThunk('user/fetchAllUsers', async (payload, { dispatch, rejectWithValue }) => {
  try {
    const response = await backendAPI.get('users', { withCredentials: true });
    return response; // Return a value synchronously using Async-await
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response);
  }
});

const userSlice = createSlice({
  name: 'user',
  initialState: {
    loading: true,
    error: '',
    currentUser: {},
    all_users: []
  },
  reducers: {},
  extraReducers: {
    // Add reducers for additional action types here, and handle loading state as needed
    //pending is loading
    [fetchUser.pending]: (state, action) => {
      state.loaded = true;
    },
    //Success
    [fetchUser.fulfilled]: (state, action) => {
      // Add user to the state object
      state.loading = false;
      state.currentUser = action.payload.data;
    },
    //Error
    [fetchUser.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [fetchAllUsers.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchAllUsers.fulfilled]: (state, action) => {
      // Add user to the state object
      state.loading = false;
      state.all_users = action.payload.data;
    }
  }
});

//Select slice name to get access to states
export const userSelector = state => state.user;

export const {} = userSlice.actions;

export default userSlice.reducer;
