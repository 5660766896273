import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Layout from './layouts/Layout';
import { Helmet } from 'react-helmet';

import 'react-toastify/dist/ReactToastify.min.css';
import 'react-datetime/css/react-datetime.css';
import 'react-image-lightbox/style.css';

const App = () => {
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <Helmet>
        <meta name="referrer" content="no-referrer-when-downgrade" />
      </Helmet>
      <Layout />
    </Router>
  );
};

export default App;
