import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import backendAPI from '../apis/backendAPI';

//Fetch list of group data
export const fetchGroups = createAsyncThunk('group/fetchGroups', async () => {
  const response = await backendAPI.get('/groups', { withCredentials: true });

  //Group total
  // alert(response.data[1].total);

  return (response.data[0].data = _.forEach(response.data[0].data, item => {
    item.created_at = new Date(item.created_at).toLocaleString();
    item.updated_last = new Date(item.updated_last).toLocaleString();
  }));
});

//Fetch list of created groups and convert for selection
export const selectGroups = createAsyncThunk('group/selectGroups', async () => {
  const response = await backendAPI.get('/groups', { withCredentials: true });

  let myArr = [];

  let selected = (response.data[0].data = _.forEach(response.data[0].data, item => {
    item.created_at = new Date(item.created_at).toLocaleString();
    item.updated_last = new Date(item.updated_last).toLocaleString();
  }));

  for (let i = 0; i < selected.length; i++) {
    myArr.push({
      value: selected[i].id,
      label: selected[i].name
    });
  }

  return myArr;
});

//Fetch list of users inside of groups
export const selectUserGroup = createAsyncThunk('group/selectUserGroup', async group_id => {
  const response = await backendAPI.get(`/groups/${group_id}`, { withCredentials: true });

  return response.data.users;
});

//Fetch list of users and convert for selection
export const selectRecipients = createAsyncThunk('group/selectRecipients', async () => {
  const response = await backendAPI.get('/users', { withCredentials: true });

  let users = response.data;
  let myArr = [];

  for (let i = 0; i < users.length; i++) {
    myArr.push({
      value: users[i].net_id,
      label: users[i].first_name + ' ' + users[i].last_name
    });
  }

  return myArr;
});

export const createGroup = createAsyncThunk('group/createGroup', async data => {
  var netidArr = [];
  let selectedFile = data.selectedFile;

  // Only File was selected
  if (selectedFile && data.data.users === undefined) {
    for (var i = 0; i < selectedFile.length; i++) {
      netidArr.push(selectedFile[i]);
    }
  }
  //If file and users manually entered
  else if (selectedFile && data.data.users) {
    for (var i = 0; i < selectedFile.length; i++) {
      netidArr.push(selectedFile[i]);
    }
    netidArr = netidArr.concat(data.data.users);
  }
  // just users no excel file
  else {
    netidArr = data.data.users;
  }

  // alert(netidArr);

  let groupJson = {
    name: data.data.name,
    description: data.data.description,
    users: netidArr,
    visible: data.data.visible
  };

  // alert(JSON.stringify(groupJson));

  await backendAPI
    .post('/groups', groupJson, { withCredentials: true })
    .then(response => {})
    .catch(error => {
      alert(error);
    });

  return groupJson;
});

export const deleteGroup = createAsyncThunk('group/deleteGroup', async group_id => {
  await backendAPI
    .delete('/groups/' + group_id, { withCredentials: true })
    .then(response => {})
    .catch(error => {
      alert(error);
    });
});

export const editGroup = createAsyncThunk('group/editGroup', async data => {
  let id = JSON.stringify(data.selectedGroup.id);

  var netidArr = [];
  let selectedFile = data.selectedFile;

  // alert(data.viewUserGroup);
  // alert(data.selectedFile);

  // Only File was selected
  if (selectedFile && data.data.users === undefined) {
    for (var i = 0; i < selectedFile.length; i++) {
      netidArr.push(selectedFile[i]);
    }
  }
  //If file and new users manually entered
  else if (selectedFile && data.data.users) {
    for (var i = 0; i < selectedFile.length; i++) {
      netidArr.push(selectedFile[i]);
    }
    netidArr = netidArr.concat(data.data.users);
  }
  // just new users no excel file
  else if (selectedFile === undefined && data.data.users) {
    netidArr = data.data.users;
  }

  //Delete user from current list
  if (data.deleteUser !== undefined) {
    //get old existing users
    netidArr = netidArr.concat(data.viewUserGroup);

    // alert(netidArr);

    //remove user from existing user
    netidArr = netidArr.filter(x => x != data.deleteUser);
    // alert(netidArr);
  }
  //Add old existing users
  else if (data.deleteUser === undefined) {
    netidArr = netidArr.concat(data.viewUserGroup);
  }

  // alert(netidArr);

  var myeditObjectGroup = {};

  //Just Group Changes, no Users
  if (data.data.users === undefined) {
    myeditObjectGroup = {
      name: data.data.name ? data.data.name : data.selectedGroup.name,
      description: data.data.description ? data.data.description : data.selectedGroup.description,
      visible: data.data.visible
    };
  }
  //Group Change and User Change
  else if (data.data.users !== undefined) {
    myeditObjectGroup = {
      name: data.data.name ? data.data.name : data.selectedGroup.name,
      description: data.data.description ? data.data.description : data.selectedGroup.description,
      visible: data.data.visible,
      users: netidArr
    };
  }
  //delted user
  if (data.data.users === undefined && data.deleteUser !== undefined) {
    myeditObjectGroup = {
      name: data.data.name ? data.data.name : data.selectedGroup.name,
      description: data.data.description ? data.data.description : data.selectedGroup.description,
      visible: data.data.visible,
      users: netidArr
    };
  }
  // Group changes and User Change
  else {
    myeditObjectGroup = {
      name: data.data.name ? data.data.name : data.selectedGroup.name,
      description: data.data.description ? data.data.description : data.selectedGroup.description,
      visible: data.data.visible,
      users: netidArr
    };
  }

  // alert(JSON.stringify(myeditObjectGroup));

  // Just Group Changes, no Users
  await backendAPI
    .post(`/groups/${id}`, myeditObjectGroup, { withCredentials: true })
    .then(response => {})
    .catch(error => {
      alert(error);
    });

  //END
});

const groupSlice = createSlice({
  name: 'group',
  initialState: {
    loaded: false,
    error: '',
    groups: [{}],
    modalName: '',
    reload: false,
    createModal: false,
    deleteModal: false,
    groupSelect: [],
    recipientSelect: [],
    selectedRecipients: [],
    currentObject: {},
    selectedID: null,
    selectedGroup: {},
    viewUserGroup: []
  },
  reducers: {
    toggleCreateModal: (state, action) => {
      state.modalName = 'Create Group';
      state.createModal = action.payload;
    },
    toggleDeleteModal: (state, action) => {
      state.modalName = 'Delete Group';
      state.deleteModal = action.payload;
    },
    selectCurrentRecipents: (state, action) => {
      if (action.payload) {
        if (action.payload.length > 0) {
          var myArr2 = [];
          for (var i = 0; i < action.payload.length; i++) {
            myArr2.push({
              value: action.payload[i],
              label: action.payload[i]
            });
          }
        }
      }
      state.selectedRecipients = myArr2;
    },
    selectID: (state, action) => {
      state.selectedID = Number(action.payload);
    },
    selectGroup: (state, action) => {
      state.selectedGroup = action.payload;
    }
    // selectUserGroup: (state, action) => {
    //   state.viewUserGroup = action.payload;
    // }
  },
  extraReducers: {
    // Add reducers for additional action types here, and handle loading state as needed
    //pending is loading
    [fetchGroups.pending]: (state, action) => {
      state.loaded = true;
    },
    //Success
    [fetchGroups.fulfilled]: (state, { payload }) => {
      state.loaded = false;
      state.groups = payload;
      state.reload = false;
    },
    // //Success
    // [fetchGroups.fulfilled]: (state, action) => {
    //   state.loaded = false;
    //   state.groups = action.payload;
    // },
    //Error
    [fetchGroups.rejected]: (state, action) => {
      state.loaded = false;
      state.error = action.error;
    },
    [selectGroups.fulfilled]: (state, { payload }) => {
      state.loaded = false;
      state.groupSelect = payload;
    },
    [selectUserGroup.fulfilled]: (state, { payload }) => {
      state.loaded = false;
      state.viewUserGroup = payload;
      state.reload = false;
    },
    [selectRecipients.fulfilled]: (state, { payload }) => {
      state.loaded = false;
      state.recipientSelect = payload;
    },
    [createGroup.fulfilled]: (state, { payload }) => {
      state.reload = true;
    },
    [editGroup.fulfilled]: (state, { payload }) => {
      state.reload = true;
    },
    [deleteGroup.fulfilled]: (state, { payload }) => {
      state.reload = true;
    }
  }
});

//States
export const groupSelector = state => state.group;

//Functions
export const {
  toggleCreateModal,
  toggleDeleteModal,
  selectCurrentRecipents,
  selectID,
  selectGroup
} = groupSlice.actions;

//export single reducers
export default groupSlice.reducer;
