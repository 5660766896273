import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';
import backendAPI from '../apis/backendAPI';

export const fetchSurveys = createAsyncThunk('survey/fetchSurveys', async () => {
  const response = await backendAPI.get('/surveys/main', { withCredentials: true });
  response.data = _.forEach(response.data, item => {
    item.created_on = new Date(item.created_on).toLocaleString();
    if (item.sent === true) {
      item.sent = new Date(item.sent_on).toLocaleString();
    } else {
      item.sent = 'Not Sent';
      item.expiration_date = 'Not Sent';
    }
  });
  return response.data;
});

export const getSurvey = createAsyncThunk('survey/getSurveys', async id => {
  const response = await backendAPI.get(`/surveys/${id}/responses`, { withCredentials: true });

  // alert(JSON.stringify(response.data));
  return response.data[0];
});

export const createSurveys = createAsyncThunk('survey/createSurveys', async formValues => {
  const response = await backendAPI.post('/surveys/add', formValues, { withCredentials: true });

  return response.data;
});

export const editSurveys = createAsyncThunk('survey/editSurveys', async data => {
  let id = JSON.stringify(data.selectedSurvey.id);
  let bs = JSON.stringify(data.selectedSurvey);

  let formValues = JSON.stringify(data.data);

  //need to add expiration_date null here because its required in endpoint
  // let nullExpiration = {
  //   expiration_date: null
  // };

  // alert(JSON.stringify(data.selectedSurvey));

  var result = _.merge({}, data.selectedSurvey, data.data);

  // alert(JSON.stringify(data.data));

  // alert(JSON.stringify(result));

  backendAPI
    .post(`/surveys/${id}/edit`, result, { withCredentials: true })
    .then(response => {})
    .catch(error => {
      alert(error);
    });
});

export const deleteSurveys = createAsyncThunk('survey/deleteSurveys', async id => {
  await backendAPI
    .get(`/surveys/${id}/remove`, { withCredentials: true })
    .then(response => {})
    .catch(error => {
      alert(error);
    });
});

export const sendSurveys = createAsyncThunk('survey/sendSurveys', async data => {
  let id = JSON.stringify(data.selectedSurvey.id);
  let recipients = data.data.recipients;
  let group = JSON.stringify(data.data.associated_groups);

  var result = _.merge({}, data.selectedSurvey, data.data);

  // alert(JSON.stringify(result));

  //Sending to ONLY recipients
  if (group === undefined && recipients !== null) {
    let payload = {
      recipients: recipients,
      groups: null,
      expiration_date: result.expiration_date
    };

    await backendAPI
      .post(`/surveys/${id}/send`, payload, { withCredentials: true })
      .then(response => {})
      .catch(error => {
        alert(error);
      });
  }

  //Sending to ONLY Groups
  else if (group !== undefined && recipients === undefined) {
    let groupID = JSON.stringify(data.data.associated_groups[0].value);
    // let groupName = JSON.stringify(data.data.associated_groups[0].label);

    let groupArray = [];

    data.data.associated_groups.forEach(function(arrayItem) {
      groupArray.push(arrayItem.value.toString());
    });

    let payload = {
      recipients: null,
      groups: groupArray,
      expiration_date: result.expiration_date
    };

    await backendAPI
      .post(`/surveys/${id}/send`, payload, {
        withCredentials: true,
        timeout: 60000
      })
      .then(response => {})
      .catch(error => {
        alert(error);
      });
  }

  //Sending to BOTH Groups and RECIPIENTS
  else {
    let groupArray = [];

    data.data.associated_groups.forEach(function(arrayItem) {
      groupArray.push(arrayItem.value.toString());
    });

    let payload = {
      recipients: recipients,
      groups: groupArray,
      expiration_date: result.expiration_date
    };

    await backendAPI
      .post(`/surveys/${id}/send`, payload, {
        withCredentials: true,
        timeout: 60000
      })
      .then(response => {})
      .catch(error => {
        alert(error);
      });
  }
});

export const currentObject = createAsyncThunk('survey/currentObject', async row => {
  // alert(JSON.stringify(row));
  if (row.category) {
    const res = await backendAPI.get(`/announcements`);
    const res2 = await backendAPI.get('/groups');
    var groupArr = ['Select a group name'];
    res.data = _.forEach(res.data, item => {
      if (item.id === row.id) {
        for (var i = 0; i < res2.data[0].data.length; i++) {
          groupArr.push(res2.data[0].data[i].name);
        }
        item.groupNames = groupArr;
        item.selectedGroupName = null;
        return item;
      }
    });
  } else {
    const res = await backendAPI.get(`/surveysAll`);
    const res2 = await backendAPI.get('/groups');
    var groupArr = ['Select a group name'];
    res.data = _.forEach(res.data, item => {
      if (item.id === row.id) {
        for (var i = 0; i < res2.data[0].data.length; i++) {
          groupArr.push(res2.data[0].data[i].name);
        }
        item.groupNames = groupArr;
        item.selectedGroupName = null;
        return item;
      }
    });
  }
});

const surveySlice = createSlice({
  name: 'survey',
  initialState: {
    loaded: false,
    error: '',
    surveys: [],
    modalName: '',
    createModal: false,
    editModal: false,
    deleteModal: false,
    sendModal: false,
    successModal: false,
    reload: false,
    selectedID: null,
    selectedSurvey: {},
    currObject: {},
    selectedSurveyRecipients: []
  },
  reducers: {
    toggleCreateModal: (state, action) => {
      state.modalName = 'Create Survey';
      state.createModal = action.payload;
    },
    toggleEditModal: (state, action) => {
      state.modalName = 'Edit Survey';
      state.editModal = action.payload;
    },
    toggleDeleteModal: (state, action) => {
      state.modalName = 'Delete Survey';
      state.deleteModal = action.payload;
    },
    toggleSendModal: (state, action) => {
      state.modalName = 'Send Survey';
      state.sendModal = action.payload;
    },
    toggleSuccessModal: (state, action) => {
      state.modalName = 'Survey Sent';
      state.successModal = action.payload;
    },
    selectID: (state, action) => {
      state.selectedID = Number(action.payload);
      state.reload = !state.reload;
    },
    selectSurvey: (state, { payload }) => {
      state.selectedSurvey = payload;
      state.reload = !state.reload;
    }
  },
  extraReducers: {
    // Add reducers for additional action types here, and handle loading state as needed
    //pending is loading
    [fetchSurveys.pending]: (state, action) => {
      state.loaded = true;
    },
    //Success
    [fetchSurveys.fulfilled]: (state, { payload }) => {
      state.loaded = false;
      state.surveys = payload;
      state.reload = false;
    },
    [createSurveys.fulfilled]: (state, { payload }) => {
      state.reload = true;
    },
    [editSurveys.fulfilled]: (state, { payload }) => {
      state.reload = true;
    },
    [deleteSurveys.fulfilled]: (state, { payload }) => {
      state.reload = true;
    },
    [sendSurveys.fulfilled]: (state, { payload }) => {
      state.reload = true;
    },

    //NESTED ARRAY FOR SOME REASON
    // [fetchSurveys.fulfilled]: (state, action) => {
    //   state.loaded = false;
    //   state.surveys.push(action.payload);
    // },
    //Error
    [fetchSurveys.rejected]: (state, action) => {
      state.loaded = false;
      state.error = action.error;
    },
    [currentObject.fulfilled]: (state, { payload }) => {
      state.currObject = payload;
      state.loaded = false;
    },
    //Get specific survey with id
    [getSurvey.pending]: (state, { payload }) => {
      state.loaded = true;
    },
    [getSurvey.fulfilled]: (state, { payload }) => {
      // state.selectedSurveyRecipients = payload;
      state.selectedSurvey = payload;

      // state.loaded = false;
      // state.reload = !state.reload;
    }
  }
});

//States
export const surveySelector = state => state.survey;

//Functions
export const {
  toggleCreateModal,
  toggleEditModal,
  toggleDeleteModal,
  toggleSendModal,
  toggleSuccessModal,
  selectID,
  selectSurvey
} = surveySlice.actions;

//export single reducers
export default surveySlice.reducer;
