import React, { Fragment, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { CloseButton, Fade } from '../components/common/Toast';

// import DashboardLayout from './DashboardLayout';
import ErrorLayout from './ErrorLayout';

import { authSelector, isLoggedIn } from '../store/authSlice';
import { useDispatch, useSelector } from 'react-redux';

import loadable from '@loadable/component';
const DashboardLayout = loadable(() => import('./DashboardLayout'));
const AuthBasicLayout = loadable(() => import('./AuthBasicLayout'));
const Landing = loadable(() => import('../components/landing/Landing'));
const About = loadable(() => import('../components/landing/About'));
const Contact = loadable(() => import('../components/landing/Contact'));
const Demo = loadable(() => import('../components/landing/Demo'));
const WizardLayout = loadable(() => import('../components/auth/wizard/WizardLayout'));
const AuthCardRoutes = loadable(() => import('../components/auth/card/AuthCardRoutes'));
const AuthSplitRoutes = loadable(() => import('../components/auth/split/AuthSplitRoutes'));
const Users = loadable(() => import('../pages/Users/Users'));

const Layout = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(isLoggedIn());
    DashboardLayout.preload();
    AuthBasicLayout.preload();
    Landing.preload();
    About.preload();
    Contact.preload();
    Demo.preload();
    WizardLayout.preload();
    AuthCardRoutes.preload();
    AuthSplitRoutes.preload();
    Users.preload();
  }, []);

  const { isAuthenticated } = useSelector(authSelector);

  const renderPrivate = () => {
    if (isAuthenticated === false || null) {
      return <Redirect to={{ pathname: '/authentication/basic/login' }} />;
    } else {
      return <Route component={DashboardLayout} />;
    }
  };

  return (
    <Router fallback={<span />}>
      <Switch>
        <Route path="/" exact component={Landing} />
        <Route path="/about" exact component={About} />
        <Route path="/contact" exact component={Contact} />
        <Route path="/demo" exact component={Demo} />
        <Route path="/authentication/basic" component={AuthBasicLayout} />
        <Route path="/errors" component={ErrorLayout} />

        {renderPrivate()}

        {/* <Route component={DashboardLayout} /> */}
      </Switch>
      <ToastContainer transition={Fade} closeButton={<CloseButton />} position={toast.POSITION.BOTTOM_LEFT} />
    </Router>
  );
};

export default Layout;
