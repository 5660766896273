import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import backendAPI from '../apis/backendAPI';

export const logoutUser = createAsyncThunk('auth/logoutUser', async () => {
  const response = await backendAPI.request('/logout', { withCredentials: true });
  return response.data;
});

export const isLoggedIn = createAsyncThunk('auth/isLoggedIn', async () => {
  const response = await backendAPI.request('/is-logged-in', { withCredentials: true });
  return response.data;
});

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    loaded: false,
    isAuthenticated: false,
    error: ''
  },
  reducers: {
    setAuthState(state, { payload }) {
      state.isAuthenticated = payload;
    }
  },
  extraReducers: {
    // // Add reducers for additional action types here, and handle loading state as needed
    [logoutUser.fulfilled]: (state, action) => {
      state.isAuthenticated = false;
    },
    [isLoggedIn.fulfilled]: (state, action) => {
      state.isAuthenticated = true;
    }
  }
});

//Select slice name to get access to states
export const authSelector = state => state.auth;

export const { setAuthState } = authSlice.actions;

//export single reducers
export default authSlice.reducer;
