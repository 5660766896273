import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';
import backendAPI from '../apis/backendAPI';

export const fetchCodes = createAsyncThunk('code/fetchCodes', async () => {
  const response = await backendAPI.get('/codes', { withCredentials: true });
  response.data = _.forEach(response.data, item => {
    //converts UTC to local time
    item.expiration_date = new Date(item.expiration_date).toLocaleString();
    // console.log('Expiration date for code created is ' + item.expiration_date);
    item.date_created = new Date(item.date_created).toLocaleString();
    for (var i = 0; i < item.associated_groups.length; i++) {
      if (i + 1 === item.associated_groups.length) {
      } else {
        item.associated_groups[i] = item.associated_groups[i];
      }
    }
  });
  return response.data;
});

export const createCode = createAsyncThunk('code/createCode', async formValues => {
  var myassociatedgroups = [];
  var myassociatedgroupsID = [];

  for (var i = 0; i < formValues.associated_groups.length; i++) {
    myassociatedgroups.push(formValues.associated_groups[i].label);
    myassociatedgroupsID.push(formValues.associated_groups[i].value);
  }

  var myobj = {
    name: formValues.name,
    code: formValues.code,
    expiration_date: new Date(formValues.expiration_date).toLocaleString(),
    associated_groups: myassociatedgroups,
    associated_groupsID: myassociatedgroupsID
  };

  await backendAPI
    .post('/codes', myobj, { withCredentials: true })
    .then(response => {})
    .catch(error => {
      alert(error);
    });

  return myobj;
});

export const deleteCode = createAsyncThunk('code/deleteCode', async code_id => {
  await backendAPI
    .delete('/codes/' + code_id, { withCredentials: true })
    .then(response => {})
    .catch(error => {
      alert(error);
    });
});

export const editCode = createAsyncThunk('code/editCode', async data => {
  let id = JSON.stringify(data.selectedCode.id);

  //Data from the form that is CHANGED
  // {
  //   "code":"ABCD",
  //   "name":"Free Coffee",
  //   "date_created":"9/25/2020, 1:22:25 AM",
  //   "associated_groups":[{"value":321,"label":"B"}] <--- New
  // }

  // data.data is CHANGED FORMS VALUES
  // data.selected is DEFAULT VALUES

  let associated_groups = [];
  let associated_groupsID = [];

  //If there is a change of groups, add to the group arrays
  if (data.data.associated_groups) {
    // Label = Group name | value = Group ID

    // associated_groups.push(data.selectedCode.associated_groups.toString()); //Default Groups converted
    // associated_groupsID.push(data.selectedCode.associated_groupsID.toString()); //Default Group IDs

    //Loop through multiple groups
    for (var i = 0; i < data.data.associated_groups.length; i++) {
      associated_groups.push(data.data.associated_groups[i].label); //New Groups
      associated_groupsID.push(data.data.associated_groups[i].value); //New Group IDS
    }
  }

  // alert(JSON.stringify(associated_groups));
  // alert(JSON.stringify(associated_groupsID));

  var result = {
    name: data.data.name ? data.data.name : data.selectedCode.name,
    code: data.data.code ? data.data.code : data.selectedCode.code,
    expiration_date: data.data.expiration_date ? data.data.expiration_date : data.selectedCode.expiration_date,
    associated_groups: data.data.associated_groups ? associated_groups : data.selectedCode.associated_groups,
    associated_groupsID: data.data.associated_groups ? associated_groupsID : data.selectedCode.associated_groupsID
  };

  // alert(JSON.stringify(result));

  await backendAPI
    .post(`/codes/${id}/edit`, result, { withCredentials: true })
    .then(response => {})
    .catch(error => {
      alert(error);
    });
});

const codeSlice = createSlice({
  name: 'code',
  initialState: {
    loaded: false,
    error: '',
    codes: [],
    modalName: '',
    createModal: false,
    deleteModal: false,
    editModal: false,
    formValues: '',
    groups: [],
    reload: false,
    selectedID: null,
    selectedCode: {}
  },
  reducers: {
    toggleCreateModal: (state, action) => {
      state.modalName = 'Create Code';
      state.createModal = action.payload;
    },
    toggleDeleteModal: (state, action) => {
      state.modalName = 'Delete Code';
      state.deleteModal = action.payload;
    },
    toggleEditModal: (state, action) => {
      state.modalName = 'Edit Code';
      state.editModal = action.payload;
    },
    submitForm: (state, action) => {
      state.formValues = action.payload;
      state.reload = !state.reload;
    },
    selectID: (state, action) => {
      state.selectedID = Number(action.payload);
      state.reload = !state.reload;
    },
    selectCode: (state, action) => {
      state.selectedCode = action.payload;
      state.reload = !state.reload;
    }
  },
  extraReducers: {
    // Add reducers for additional action types here, and handle loading state as needed
    //pending is loading
    [fetchCodes.pending]: (state, action) => {
      state.loaded = true;
    },
    //Success
    [fetchCodes.fulfilled]: (state, { payload }) => {
      state.loaded = false;
      state.codes = payload;
      state.reload = false;
    },
    //Error
    [fetchCodes.rejected]: (state, action) => {
      state.loaded = false;
      state.error = action.error;
    },
    [createCode.fulfilled]: (state, { payload }) => {
      state.reload = true;
    },
    [editCode.fulfilled]: (state, { payload }) => {
      state.reload = true;
    },
    [deleteCode.fulfilled]: (state, { payload }) => {
      state.reload = true;
    }
  }
});

//States
export const codeSelector = state => state.code;

//Functions
export const {
  toggleCreateModal,
  toggleDeleteModal,
  toggleEditModal,
  submitForm,
  selectID,
  selectCode
} = codeSlice.actions;

//export single reducers
export default codeSlice.reducer;
